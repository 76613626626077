'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider",
        function($stateProvider) {
            $stateProvider
                .state('index.notifications', {
                    url: '/notifications',
                    templateUrl: 'app/notifications/notifications.html',
                    controller: 'NotificationCtrl'
                })
        }
    ]);